<template>
  <common-page-table
    :titleTable="$t('Путешественники')"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/contract`"
    :prefixShow="`/contract`"
    :linkCreate="`/travelers/create`"
    :keyTable="'link'"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :forceIsCreate="isCreate"
    :forceDelete="isCreate"
    :force-is-edit="false"
  />
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import {
  API_METHODS,
  FIELD_LANG_PERSON,
  GLOBAL_DATE_FORMAT
} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../../helpers/converters/formatData";
export default {
  name: "TablePagePerson",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "FIELD_LANG_PERSON",
      apiName: API_METHODS.GET_ALL_TREVELRS,
      apiDeleteName: API_METHODS.DELETE_PERSON,
      routeField: "PersonId"
    };
  },
  computed: {
    paramsGetData() {
      return {
        id: this.$route.params[this.routeField]
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.travelers;
    },
    isCreate() {
      let res = true;
      if (this.$route.name == "travelersPage") {
        res = false;
      }
      return res;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, "", false);

      res = res.map(el => {
        return {
          ...el,
          link: `${el.contractId}/person/${el.personId}`,
          dateBirth: this.$moment(el.dateBirth, "YYYY-MM-DD").format(
            GLOBAL_DATE_FORMAT
          )
        };
      });
      return res;
    },
    deleteParams(id, row) {
      return {
        PersonId: row.personId,
        CompanyId: row.companyId
      };
    }
  }
};
</script>
